/* Alapvető stílusok az oldalhoz */
body {
  font-family: Arial, sans-serif;
  background-color: #2c2c2c;
  color: #ffffff;
}

/* A fő konténer stílusai, amely az összes elemet tartalmazza */
.container {
  padding: 40px;
  max-width: 600px;
  margin: 0 auto;
}

/* A fejléc szöveg stílusai */
.text {
  font-size: 22px;
  text-align: center;
}

/* Verziószám stílusai a fejlécben */
.versionText {
  font-size: 16px;
  color: #bbbbbb;
}

/* A heartbeat lámpa stílusai */
.lamp {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #39FF14;
  margin-left: 15px;
}

/* Aktív állapotban lévő lámpa stílusai */
.lamp.active {
  background-color: #800080;
}

/* Kapcsolókat és gombokat tartalmazó sorok stílusai */
.row {
  display: flex;           /* Flexbox aktiválása */
  flex-wrap: wrap;         /* Sor törés engedélyezése */
  justify-content: space-evenly; /* Egyenletes vízszintes elosztás */
  align-items: center;     /* Kapcsolók függőlegesen igazítva */
  gap: 10px;               /* Egyenletes térköz minden irányban */
  margin-bottom: 5px;     /* Csökkentett alsó margó */
}

/* Kapcsoló szövegének stílusai */
.switchText {
  font-size: 20px;
  margin-bottom: 10px;
  color: #ffffff;
}

/* Kapcsoló thumb (pötty) mérete és stílusai */
input[type="checkbox"].customSwitch {
  width: 80px;
  height: 40px;
  cursor: pointer;
  appearance: none;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  transition: background-color 0.3s ease;
}

/* Bekapcsolt állapot */
.customSwitch:checked {
  background-color: #FF4500;
}

/* Kikapcsolt állapot pötty színe */
.customSwitch::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  background-color: #39FF14;
  border-radius: 50%;
  transition: left 0.3s ease;
}

/* Bekapcsolt pötty mozgatása és színének módosítása neonpirosra */
.customSwitch:checked::before {
  left: 45px;
  background-color: #FF073A;
}

/* Gombok alapvető stílusai */
.button {
  display: flex;
  align-items: center;
  justify-content: center; /* Középre igazítja a szöveget */
  background-color: #1E90FF;
  color: #ffffff;
  padding: 15px 30px;
  margin: 10px;
  border: none;
  border-radius: 25px; /* Lekerekítés hozzáadva */
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #007acc;
}


/* Gombokat tartalmazó grid konténer stílusai */
.buttonGrid {
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* Biztonsági kód beállító gombok és cím sorának stílusai */
.securityCodeRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

/* Biztonsági kód cím stílusai */
.securityCodeLabel {
  font-size: 16px;
  color: #ffffff;
  margin-right: 10px;
}

/* Biztonsági kód +/- gombok stílusai */
.counterButtonSmall {
  background-color: #ff073a;
  border: none;
  padding: 10px 20px;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

/* Biztonsági kód aktuális értékének szöveg stílusai */
.counterTextSmall {
  margin: 0 20px;
  font-size: 18px;
}

/* Válasz szöveg stílusai */
.responseText {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

/* Bejelentkezési űrlap stílusai */
.loginForm {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #444444;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

/* Bejelentkezési űrlap címke stílusai */
.loginForm label {
  display: block;
  font-size: 16px;
  color: #ffffff;
  margin: 10px 0 5px 0;
}

/* Bejelentkezési űrlap mezők stílusai */
.loginForm input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

/* Bejelentkezési gomb stílusai */
.loginForm button {
  width: 100%;
  padding: 10px;
  background-color: #1E90FF;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Bejelentkezési gomb hover állapotának stílusai */
.loginForm button:hover {
  background-color: #187bcd;
}

/* Heartbeat állapot megjelenítése szövegesen */
.heartbeatStatus {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  color: #ffffff;
}

/* Középre igazított cím stílusa */
.centeredTitle {
  text-align: center;
  margin-left: 10px;      /* Csak a szükséges távolság a cím után */
}

/* Fejléc sor, amely tartalmazza a címet és a heartbeat jelzőt */
.headerRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  position: relative; /* A pozicionálás lehetővé tétele */
}

.backButton {
  background-color: #1E90FF;
  color: #ffffff;
  padding: 2px 10px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 12px;
  position: absolute; /* Pozicionálás */
  left: 0; /* Bal szélhez igazítva */
  transition: background-color 0.3s ease;
}

.backButton:hover {
  background-color: #007acc;
}

.clearButton {
  background-color: #e6e600; /* Halvány neon sárga */
  color: #000000;
  padding: 2px 10px; /* Méret visszaállítása */
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 12px;
  position: absolute; /* Balra igazításhoz */
  left: 0;
  transition: background-color 0.3s ease;
}

.clearButton:hover {
  background-color: #cccc00;
}

/* Vendég lap stílusai 
.switchRow {
  display: flex;
  justify-content: center;
  margin-top: 20px;
} */
.switchRow {
  display: flex;
  justify-content: space-between; /* Egyenletesen elosztja az elemeket */
  align-items: center; /* Függőlegesen középre igazítja őket */
  flex-wrap: wrap; /* Tördelés kisebb képernyőn */
  width: 100%; /* Szülőelem szélességéhez igazítás */
  margin: 10px 0; /* Térköz a sorok között */
}


.switchRow label {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  gap: 10px;
}

.remainingTimeBox {
  background-color: #f0f0f0;
  color: #333;
  padding: 3px 8px; /* További csökkentés a paddingon */
  margin: 20px auto; /* Nagyobb margó felül, hogy távolabb kerüljön a kapcsolóktól */
  border-radius: 15px;
  text-align: center;
  font-weight: bold;
  width: 80%;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  line-height: 0.9; /* Tovább csökkentett sor magasság */
}

.heartbeat-dot {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-left: 20px;
  border-radius: 50%;
}

.heartbeat-dot.green {
  background-color: #39FF14; /* Zöld szín */
}

.heartbeat-dot.red {
  background-color: #800080; /* Piros szín */
}

/* Hőmérséklet doboz stílusai */

.temperatureRow {
  display: flex;            /* Flexbox elrendezés a boxok számára */
  justify-content: center;  /* Középre igazítás */
  gap: 20px;                /* Térköz a boxok között */
  margin-top: 20px;         /* További térköz a sor fölött */
}

.temperatureBox {
  border: 4px solid #585a6f;
  padding: 10px;
  border-radius: 12px;
  background-color: #ccc;
  text-align: center;
  margin: 10px 0;
  color: #000;
  height: 55px; /* Rögzített magasság */
  width: 150px; /* Rögzített szélesség */
  box-sizing: border-box; /* Biztosítja, hogy a padding ne befolyásolja a szélességet */
  
  display: flex; /* Flexbox aktiválása */
  flex-direction: column; /* Sorok elrendezése oszlopban */
  justify-content: center; /* Függőleges középre igazítás */
  align-items: center; /* Vízszintes középre igazítás */
}


.temperatureBox h3 {
  margin: 5px 0; /* Szoros margók */
  font-size: 0.8em; /* Fejléc betűméret igazítása */
}

.temperatureBox p {
  margin: 5px 0; /* Szoros margók */
  font-size: 1.2em; /* Tartalom betűméret igazítása */
  line-height: 0.6; /* Sorok közötti távolság szabályozása */
}

/* --------------------------------------------------------------------------------------- */

.switchRow {
  display: flex;
  justify-content: space-evenly; /* Egyenletes elosztás */
  align-items: center; /* Függőleges középre igazítás */
  flex-wrap: wrap; /* Tördelés kisebb képernyőkön */
  gap: 20px; /* Térköz az elemek között */
  margin: 20px 0; /* Felső és alsó térköz */
}

.switchContainer {
  border: 1px solid #ccc; /* Halvány keret */
  border-radius: 8px; /* Lekerekített sarkok */
  /*padding: 10px; /* 10 Belső térköz */
  background-color: #444; /* Sötétszürke háttér */
  color: #fff; /* Fehér szöveg */
  text-align: center; /* Középre igazítás */
  width: 150px; /* Egységes szélesség */
  /*margin: 10px; /* Külső térköz */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Árnyék a kiemeléshez */
  width: 90px;
}

.switchContainer h3 {
  margin: 0;
  font-size: 16px; /* Cím méret */
  color: #fff; /* Fehér szöveg */
}

.switchContainer p {
  margin: 5px 0;
  font-size: 14px; /* Érték méret */
  font-weight: bold;
  color: #bbb; /* Halványabb fehér */
}

/* Toggle Container: First row 3 toggles, second row 1 toggle centered */
.toggleContainer {
  display: flex;
  flex-direction: column;   /* Szöveg és kapcsoló egymás alá kerül */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; /* Középre igazítás vízszintesen */
  margin: 10px 0;
  gap: 5px;
}

.toggleContainer .toggleRow {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
}

.toggleContainer .toggleRow.single {
  display: flex;
  flex-direction: column; /* Szöveg és kapcsoló egymás alá kerül */
  align-items: center; /* Középre igazítás vízszintesen */
  gap: 3px; /* Távolság a szöveg és a kapcsoló között */
  justify-content: center;
}

/* Button Container: 3 buttons in a single row */
.buttonContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px 0;
}

.centeredTitle h4 {
  font-size: 0.5em; /* Kisebb méret */
  display: inline; /* Ugyanabban a sorban jelenjen meg */
  margin-left: 8px; /* Kis térköz a főcím után */
}